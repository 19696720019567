<script setup>
    import PrimevueToast from 'primevue/toast';

    const localePath = useLocalePath();
    const { t: $t } = useI18n();

    // Add data layout attribute to body
    useHead({
        bodyAttrs: {
            'data-layout': 'register-steps'
        },
    });
</script>

<template>
    <!-- Toast -->
    <PrimevueToast position="top-right" group="tr" />

    <header>
        <div id="header__logo">
            <LayoutLogo />
        </div>

        <div id="header__goto">
            <NuxtLink :to="localePath('index')">
            <span>{{ $t('See all lots online') }}</span>
                <Icon name="material-symbols:arrow-right-alt-rounded" />
            </NuxtLink>
        </div>
    </header>

    <main>
        <div id="app__header">
            <slot name="header" />
        </div>

        <!-- Register steps -->
        <PrimevueSteps previousClass="p-steps-success" :model="[
            {label: $t('Activity'), to: localePath('register-activity')},
            {label: $t('Profile'), to: localePath('register-profile')},
            {label: $t('Ended'), to: localePath('register-confirmation')},
        ]" />

        <slot />
    </main>

    <footer>
        <LayoutCopyright />
    </footer>
</template>
